import Vue from 'vue/dist/vue.esm';

// Axios defaults
import axios from 'axios';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';

import validate from './config/validate.js';

import './styles/tailwind.css';
import './styles/guides.scss';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { currencyMix } from '../shared/currency_mix';

Sentry.init({
  dsn: 'https://4b96b973744b4bf2ab5f5921513d01ca@sentry.io/256247',
  integrations: [new VueIntegration({ Vue, attachProps: true, logErrors: true })],
  environment: `${process.env.WIQ_ENV}`,
});

import Activate from './activate.vue';
import BenefitsCarousel from './benefits-carousel.vue';
import BoosterPricing from './pricing/booster-pricing.vue';
import Check from './icons/check.vue';
import ClubPricing from './pricing/club-pricing.vue';
import CollegePricing from './pricing/college-pricing.vue';
import ContactForm from './contact-form.vue';
import ErrorIcon from './icons/error-icon.vue';
import FeatureQuote from './feature-quote.vue';
import HighschoolPricing from './pricing/highschool-pricing.vue';
import HomeTabs from './home-tabs.vue';
import Minus from './icons/minus.vue';
import PricingGrid from './pricing/pricing-grid.vue';
import RtcPricing from './pricing/rtc-pricing.vue';

document.addEventListener('DOMContentLoaded', () => {
  // Setup the axios and vee-validate rules
  validate();

  const app = new Vue({
    el: '#tailwind-marketing',
    mixins: [currencyMix],
    components: {
      Activate,
      BenefitsCarousel,
      BoosterPricing,
      Check,
      ClubPricing,
      CollegePricing,
      ContactForm,
      ErrorIcon,
      FeatureQuote,
      HighschoolPricing,
      HomeTabs,
      Minus,
      PricingGrid,
      RtcPricing,
    },
    data: {
      transitions: {},
    },
    mounted() {
      Array.from(document.querySelectorAll('.transition-init')).forEach(function(el) {
        el.classList.remove('transition-init');
      });
      document.addEventListener('click', this.close)
    },
    beforeDestroy () {
      document.removeEventListener('click',this.close)
    },
    methods: {
      close(e) {
        // This handles clicking elsewhere on the page to close menus
        Object.keys(this.transitions).forEach((transitionId) => {
          let el = document.getElementById(transitionId);
          let containsButton = e.srcElement.classList.contains("transition-button");
          let parentContainsButton = e.srcElement.closest(".transition-button");
          // This says if we have an element on the dom, we do not have the transition button trigger class,
          // we do not have a parent with said class, and the click does not land on the transitioned object itself..
          // close it
          // I.e. If a flyout menu is open, close it with a click anywhere else on the dom
          if (el && !containsButton && !parentContainsButton && !el.contains(e.target)) {
            this.$set(this.transitions, transitionId, false);
          }
        });
      },
      toggleTransition(id) {
        if (!this.transitions[id]) {
          this.$set(this.transitions, id, true);

          // Special case, make sure we close the desktop feature / built for menus if the other is clicked
          if (id === 'builtForFlyout') {
            this.$set(this.transitions, 'featureFlyout', false);
            this.$set(this.transitions, 'resourcesFlyout', false);
          } else if (id === 'featureFlyout') {
            this.$set(this.transitions, 'builtForFlyout', false);
            this.$set(this.transitions, 'resourcesFlyout', false);
          } else if (id === 'resourcesFlyout') {
            this.$set(this.transitions, 'featureFlyout', false);
            this.$set(this.transitions, 'builtForFlyout', false);
          }
        } else {
          this.$set(this.transitions, id, !this.transitions[id] || false);
        }
      }
    }
  });
});
